import React from "react"
import * as residenceService from "../../services/residence";
import merge from "lodash/merge";
import showCaseResidenceTplt from "../../constants/tplt/residence/showcaseResidence";
import washJson from "../../utils/washJson";
import * as serviceService from "../../services/service";
import {SubmissionError} from "redux-form";


function withResidenceTemplate(WrappedComponent, selectData) {
	return class extends React.Component {
		constructor(props) {
			super(props);

			this.state = {
				template: selectData(),
				loaded: false,
				data : {}
			};
			this.load = this.load.bind(this);

		}



		componentDidMount() {
			this.load();
		}

		load = async () => {
			this.setState({ loaded: false });
			const { defaultLocal, locals } = this.props;
			let data;

			if (this.props.match.params.id) {
				data = await residenceService.view(this.props.match.params.id);

				let floors = await residenceService.floors(this.props.match.params.id);

				data.floors = floors['hydra:member'];

				//Commenté car empêche l'edition de l'ordre des blocs
				data = merge(JSON.parse(JSON.stringify(this.state.template)), data);
				if (data.services) {
					data.services = data.services.map(el => el["@id"]);
				}
				locals.map(local => {
					if (local != defaultLocal) {
						if (!data.translate) data.translate = {};
						if (!data.translate[local]) data.translate[local] = {};
						if (!data.translate[local].builder) {
							data.translate[local].builder = JSON.parse(
								JSON.stringify(this.state.template.builder),
							);
						}

						if (typeof data.translate[local].builder !== "object")
							data.translate[local].builder = washJson(
								data.translate[local].builder,
							);
					}
				});

				this.setState({
					data: data,
					loaded: true,
				});
			} else {
				data = merge(JSON.parse(JSON.stringify(this.state.template)), {});
				data.translate = {};

				if (data.builder) {
					locals.map(el => {
						if (el != defaultLocal)
							data.translate[el] = { builder: data.builder };
					});
				}
				this.setState({
					data: data,
					loaded: true,
				});
			}
			serviceService.search().then(data => {
				this.setState({
					services: data["hydra:member"].map(el => ({
						label: el.name,
						value: el["@id"],
					})),
				});
			});
		};

		copy = async (locale) => {
			var localeWord = locale === 'en' ? 'l\'Anglais' : 'le Chinois';

			if (
				window.confirm("Voulez vous vraiment copier le contenu de la résidence vers " + localeWord  + "? Si des traductions sur cette page existe elles seront supprimées")
			) {

				return residenceService.copy(this.state.data.id, locale)
					.then(() => {
						window.location.reload();
					})
					.catch(error => {
						throw new SubmissionError(error);
					});
			}
		}


		render() {
			return <WrappedComponent
				{...this.props}
			 	withResidenceTemplateData={this.state}
				copy={this.copy}

			/>;
		}
	};
}
export default withResidenceTemplate
