import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Loading from "../../components/Loading";
import { bindActionCreators } from "redux";
import * as residenceService from "../../services/residence";
import { SubmissionError } from "redux-form";
import ResidenceForm from "./ResidenceForm";
import { toast } from "react-toastify";
import Locals from "../../components/Locals";

import {
	getLocal,
	getLocals,
	setLocal,
	getTemplates,
	getDefaultLocal,
} from "../../reducers/params";
import withResidenceTemplate from "./WithResidenceTemplate";
import residenceTplt from "../../constants/tplt/residence/residence";

class Residence extends Component {
	state = {};

	onSubmit = data => {
		const service = !data.id
			? residenceService.create
			: residenceService.update;
		const caseForm = !data.id ? "Création réussie" : "Modification réussie";

		const updatedFeatures = data.typologyFeatures
			? data.typologyFeatures
					.filter(el => el.hasOwnProperty("number"))
					.map(element => {
						return {
							...element,
							typology: element.typology["@id"],
						};
					})
			: [];

		let associatedCitiesFormated = data.associatedCities.map(e => {
			return {
				id: e.id ? e.id : e["@id"],
			}
		})
		const newData = { ...data, typologyFeatures: updatedFeatures, type : 1, associatedCities: associatedCitiesFormated };
		return service(newData)
			.then(newData => {
				if (!data.id) this.props.history.push("/residences");
				toast.success(caseForm);
			})
			.catch(error => {
				throw new SubmissionError(error);
			});
	};

	render() {
		const {  local, locals } = this.state;

		if (!this.props.withResidenceTemplateData.loaded) return <Loading />;

		return (
			<div className="animated fadeIn">
				<h1>Résidence</h1>

				<Locals />
				<ResidenceForm
					onSubmit={this.onSubmit}
					initialValues={this.props.withResidenceTemplateData.data}
					services={this.props.withResidenceTemplateData.services}
					local={local}
					locals={locals}
					copy={this.props.withResidenceTemplateData.copy}
				/>
			</div>
		);
	}
}

Residence.defaultProps = {
	local: null,
};

Residence.propTypes = {
	local: PropTypes.string,
	locals: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
	local: getLocal(state),
	locals: getLocals(state),
	defaultLocal: getDefaultLocal(state),
	templates: getTemplates(state),
});

export const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			setLocal,
		},
		dispatch,
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withResidenceTemplate(Residence, (templateSource) => residenceTplt));
