import React, { Component } from "react";
import {
	Alert,
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Col,
	Form,
	Row,
	TabContent,
	TabPane,
} from "reactstrap";
import {
	change,
	Field,
	FieldArray,
	FormSection,
	getFormValues,
	reduxForm,
} from "redux-form";
import Save from "../../components/Save";
import * as userService from "../../services/user";
import * as cityService from "../../services/city";
import * as promotionService from "../../services/promotion";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import PropTypes from "prop-types";
import {
	getCmsStatus,
	getDefaultLocal,
	getLocal,
	getSeoRobots,
} from "../../reducers/params";
import { connect } from "react-redux";
import FieldTranslate from "../../components/Form/whFields/FieldTranslate";
import Header from "./nav/Header";
import { toast } from "react-toastify";
import * as residenceService from "../../services/residence";
import Dropzone from "react-dropzone";
import Lots from "./Lots";
import TypologyFeatures from "./Feature/TypologyFeatures";
import {
	WhFieldBuilder,
	WhFieldCheckbox,
	WhFieldSelect,
	WhFieldSelectAsync,
	WhFieldTranslate,
	WhFieldTxt,
	WhFile,
	WhParseNull, whParseSelect,
	WhValidator,
} from "../../components/Form/whFields";
import * as pageService from "../../services/cmsPage";
import FormSeo from "../Cms/Seo/Form";
import ApiConfig from "../../constants/ApiConfig";
import Calendly from "./Calendly";
import Floors from "./Floor/Floors";
import ChipSelect from "../../components/ChipSelect";

class ResidenceForm extends Component {
	state = {
		file: null,
		activeTab: "contents",
		errorsImport: [],
	};

	onDrop = (file) => {
		this.setState({
			file,
			errorsImport: [],
		});
	};

	import = () => {
		const { file } = this.state;
		const { initialValues } = this.props;

		if (!file) {
			toast.error("Pas de fichier séléctionné");
			return;
		}

		let formData = new FormData();
		formData.append("file", file[0]);

		residenceService
			.importPois(formData, initialValues.id)
			.then(() => {
				this.setState({
					file: null,
				});
				toast.success("Import réussi");
			})
			.catch((error) => {
				if (error) {
					this.setState({
						errorsImport: error,
					});
				}
			});
	};

	toggle = (tab) => {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	};

	loadParents = (input) => {
		return pageService
			.search({
				filtered: [
					{
						id: "title",
						value: input,
					},
				],
			})
			.then((data) => {
				const lst = data["hydra:member"].map((el, index) => {
					return {
						id: el.id,
						label: `${el.title}`,
					};
				});
				return { options: lst };
			});
	};

	loadPromotions = (input) => {
		return promotionService
			.search({
				filtered: [
					{
						id: "name",
						value: input,
					},
				],
			})
			.then((data) => {
				const lst = data["hydra:member"].map((el, index) => {
					return {
						id: el.id,
						label: `${el.name}`,
					};
				});
				return { options: lst };
			});
	};

	loadCities = (input) => {
		return cityService
			.search({
				filtered: [
					{
						id: "page.title",
						value: input,
					},
					{
						id: "pagination",
						value: "false",
					},
				],
			})
			.then((data) => {
				const lst = data["hydra:member"].map((el) => {
					return {
						value: el.id,
						id: el.id,
						label: `${el.label}`,
					};
				});
				return { options: lst };
			});
	};

	loadManagers = (input) => this.loadMembers(input, "ROLE_MANAGER_RESIDENCE");
	loadTrainees = (input) =>
		this.loadMembers(input, "ROLE_STAGIAIRE_RESIDENCE");

	loadMembers = (input, type) => {
		return userService
			.search({
				filtered: [
					{
						id: "firstName",
						value: input,
					},
					{
						id: "role",
						value: type,
					},
					{
						id: "pagination",
						value: false,
					},
				],
			})
			.then((data) => {
				const lst = data["hydra:member"].map((el) => {
					return {
						id: el.id,
						label: `${el.name}`,
					};
				});
				return { options: lst };
			});
	};

	setCalendlyTemplate = () => {
		const { changeFieldValue, currentValues } = this.props;
		const calandlyStr = `https://calendly.com/${currentValues.slug}/visite`;
		changeFieldValue("urlCalendly", calandlyStr);
	};

	render() {
		const {
			error,
			handleSubmit,
			submitting,
			currentValues,
			local,
			services,
			cmsStatus,
			seoRobots,
			changeFieldValue,
			defaultLocal,
		} = this.props;

		const { errorsImport } = this.state;

		return (
			<Form onSubmit={handleSubmit} className="form-horizontal">
				<Row>
					<Col sm="8" xs={12} className="form-vertical">
						<Card>
							<CardHeader>
								<Header
									toggle={this.toggle}
									activeTab={this.state.activeTab}
								/>
								<Save
									data={currentValues}
									setHistory={changeFieldValue}
									type="residence"
									copy={this.props.copy}
								/>
							</CardHeader>

							<TabContent activeTab={this.state.activeTab}>
								<TabPane tabId="contents">
									<FieldTranslate
										label="Titre de la page (h1 affiché en front)"
										name="title"
										validate={[WhValidator.required]}
										component={WhFieldTxt}
										type="text"
										translatable={local}
										bsSize="lg"
									/>

									<FieldTranslate
										label="Nom de la résidence"
										name="name"
										validate={[WhValidator.required]}
										component={WhFieldTxt}
										type="text"
										translatable={local}
									/>

									<FieldTranslate
										label="Text tarif"
										name="disclamer"
										validate={[WhValidator.required]}
										component={WhFieldTxt}
										type="textarea"
										translatable={local}
									/>

									<FieldTranslate
										label="Nom dans l'url"
										name="slug"
										validate={[WhValidator.required]}
										component={WhFieldTxt}
										type="text"
										translatable={local}
									/>

									<div className="content">
										<FieldArray
											name={
												local === defaultLocal
													? `builder`
													: `translate.${local}.builder`
											}
											editable={true}
											component={WhFieldBuilder}
										/>
									</div>
								</TabPane>
								<TabPane tabId="poi">
									<Field
										label="Activer les pois ?"
										name="poiActivated"
										component={WhFieldCheckbox}
										require
									/>
									<div>
										<p>
											Fichier .csv windows avec séparateur
											";". Noms des colonnes dans l'ordre
											: <br />
											FAMILLE_POI, TITRE_POI_FR,
											TITRE_POI_EN, DESCRIPTIF_POI_FR,
											DESCRIPTIF_POI_EN, LATITUDE,
											LONGITUDE
										</p>

										<p className="alert alert-warning">
											Attention l'import de pois écrase
											tous les pois existant sur cette
											ville.
										</p>

										{errorsImport.map((val) => (
											<p className="alert alert-danger">
												{val}
											</p>
										))}

										<Dropzone
											className="dropzone"
											multiple={false}
											onDrop={this.onDrop}
										>
											{({
												isDragActive,
												isDragReject,
												acceptedFiles,
												rejectedFiles,
											}) => {
												if (isDragActive) {
													return "This file is authorized";
												}
												if (isDragReject) {
													return "This file is not authorized";
												}
												return acceptedFiles.length ||
													rejectedFiles.length
													? `Accepted ${acceptedFiles.length}, rejected ${rejectedFiles.length} files`
													: "Déposer votre fichier csv dans cette zone";
											}}
										</Dropzone>

										<Button
											color="btn btn-primary btn-block"
											onClick={this.import}
										>
											Importer
										</Button>
									</div>
								</TabPane>
								<TabPane tabId="medias">
									<Row>
										<Col md={8}>
											<FieldArray
												name="medias"
												component={WhFieldBuilder}
											/>
										</Col>

										<Col md={4}>
											<Card>
												<CardHeader>Medias</CardHeader>
												<CardBody>
													<Field
														label="Vignette push ville"
														name="cityPushVignette"
														component={WhFile}
														type="text"
													/>

													<Field
														label="Vignette push accueil"
														name="homePushVignette"
														component={WhFile}
														type="text"
													/>
												</CardBody>
											</Card>
										</Col>
									</Row>
								</TabPane>
								<TabPane tabId="lots">
									<Card>
										<CardHeader>
											Nombre de lots par typologie
										</CardHeader>
										<CardBody>
											<FieldArray
												name={`typologyFeatures`}
												component={TypologyFeatures}
											/>
										</CardBody>
									</Card>
									{this.props.initialValues.id && (
										<Card>
											<CardHeader>
												Lots de la residence
											</CardHeader>

											<Lots
												residenceId={
													this.props.initialValues.id
												}
											/>
										</Card>
									)}
									<Card>
										<CardHeader>Code Calendly</CardHeader>
										<CardBody>
											<Button
												size="xs"
												color="primary"
												onClick={
													this.setCalendlyTemplate
												}
											>
												Calendly Template
											</Button>
											<Field
												label="Lien Calendly : "
												name="urlCalendly"
												component={WhFieldTxt}
												type="text"
											/>
											<Calendly
												url={
													this.props.initialValues
														.urlCalendly
												}
											/>
										</CardBody>
									</Card>
								</TabPane>
								<TabPane tabId="seo">
									<FormSection name="seo">
										<FormSeo
											local={local}
											seoRobots={seoRobots}
										/>
									</FormSection>
								</TabPane>

								<TabPane tabId="floors">
									<FieldArray
										name={`floors`}
										component={Floors}
									/>
								</TabPane>
							</TabContent>
						</Card>
					</Col>

					<Col sm="4" xs={12}>
						<Card>
							<CardHeader>
								<i className="fa fa-align-justify" /> Edition
							</CardHeader>

							<CardBody className="form-vertical">
								<Field
									label="Code technique CRM (rattachement des lots) : "
									name="externalRef"
									validate={[WhValidator.required]}
									component={WhFieldTxt}
									type="text"
								/>

								<WhFieldTranslate
									label="Statut de publication :"
									name="status"
									required
									validate={[WhValidator.required]}
									component={WhFieldSelect}
									type="select"
									options={cmsStatus}
									translatable={local}
								/>

								<Field
									label="Statut de la résidence : "
									name="statusRelease"
									validate={[WhValidator.required]}
									component={WhFieldSelect}
									type="text"
									options={[
										{
											value: 1,
											name: "A venir",
										},
										{
											value: 2,
											name: "Disponible",
										},
									]}
									required={true}
									multiple={false}
									parse={(value) => parseInt(value)}
								/>

								<Field
									label="Adresse : "
									name="address.street"
									validate={[WhValidator.required]}
									component={WhFieldTxt}
									type="text"
								/>

								<Field
									label="Complément d'adresse : "
									name="address.cplt"
									component={WhFieldTxt}
									type="text"
								/>

								<Field
									label="Code postal : "
									name="address.zipCode"
									validate={[WhValidator.required]}
									component={WhFieldTxt}
									type="text"
								/>

								<Field
									label="Nom de ville (si différent de la ville associé) : "
									name="address.cityName"
									component={WhFieldTxt}
									type="text"
								/>

								<Field
									label="Ville :"
									name="address.city"
									component={WhFieldSelectAsync}
									type="select"
									validate={[WhValidator.required]}
									loadOptions={this.loadCities}
									parse={WhParseNull}
								/>

								<Field
									label="Villes à proximité:"
									name="associatedCities"
									component={WhFieldSelectAsync}
									type="select"
									loadOptions={this.loadCities}
									parse={whParseSelect}
									multi
								/>


								<Field
									label="Latitude"
									name="latitude"
									component={WhFieldTxt}
									type="text"
								/>

								<Field
									label="Longitude"
									name="longitude"
									component={WhFieldTxt}
									type="text"
								/>

								<Field
									label="Résidence ouverte parking ?"
									name="parkingActivated"
									component={WhFieldCheckbox}
									require
								/>

								<Field
									label="Nouvelle résidence ?"
									name="new"
									component={WhFieldCheckbox}
								/>

								<Field
									label="Desactiver les visites ?"
									name="disableVisit"
									component={WhFieldCheckbox}
								/>

								<Field
									label="Selectionner les services associés à cette résidence :"
									name="services"
									component={ChipSelect}
									type="select"
									options={services}
								/>

								{error && (
									<Alert color="danger">
										<div
											dangerouslySetInnerHTML={{
												__html: error,
											}}
										/>
									</Alert>
								)}
							</CardBody>
							<CardFooter>
								<LaddaButton
									className="btn btn-success btn-ladda btn-lg"
									loading={submitting}
									data-style={EXPAND_LEFT}
									type="submit"
								>
									<i className="fa fa-save" /> Enregistrer{" "}
								</LaddaButton>
							</CardFooter>
						</Card>
						{currentValues.id && (
							<Card>
								<a
									href={`${ApiConfig.url}/redirect/residence/${currentValues.id}`}
									target="_blank"
									className="btn btn-block btn-success btn-ld"
								>
									Voir
								</a>
							</Card>
						)}

						<Card>
							<CardHeader>
								<i className="fa fa-users" />
								Contacts résidence
							</CardHeader>
							<CardBody className="form-vertical">
								<Field
									label="Manager rattaché :"
									name="manager"
									component={WhFieldSelectAsync}
									type="select"
									loadOptions={this.loadManagers}
									parse={WhParseNull}
								/>

								<Field
									label="Stagiaire rattaché :"
									name="seller"
									component={WhFieldSelectAsync}
									type="select"
									loadOptions={this.loadTrainees}
									parse={WhParseNull}
								/>
							</CardBody>
						</Card>

						<Card>
							<CardHeader>Affichages</CardHeader>
							<CardBody className="form-vertical">
								<Field
									label="Promotion : "
									name="promotion"
									component={WhFieldSelectAsync}
									type="select"
									loadOptions={this.loadPromotions}
									parse={WhParseNull}
								/>

								<Field
									label="Afficher sur l'accueil ?"
									name="pushHome"
									component={WhFieldSelect}
									options={[
										{
											value: "no",
											name: "Non",
										},
										{
											value: "yes",
											name: "Oui",
										},
									]}
									required
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Form>
		);
	}
}

const formName = "ResidenceForm";
ResidenceForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	submitting: PropTypes.bool.isRequired,
	local: PropTypes.string,
};

ResidenceForm.defaultProps = {
	error: "",
};

const mapStateToProps = (state) => ({
	local: getLocal(state),
	cmsStatus: getCmsStatus(state),
	seoRobots: getSeoRobots(state),
	defaultLocal: getDefaultLocal(state),
	currentValues: getFormValues(formName)(state),
});

const mapDispatchToProps = (dispatch) => ({
	changeFieldValue: (field, value) =>
		dispatch(change(formName, field, value)),
});

ResidenceForm = connect(mapStateToProps, mapDispatchToProps)(ResidenceForm);

ResidenceForm = reduxForm({
	form: formName,
})(ResidenceForm);

export default ResidenceForm;
