import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import Header from "./Header/";
import Sidebar from "./Sidebar/";
import Breadcrumb from "./Breadcrumb/";
import Footer from "./Footer/";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import * as session from "../../utils/session";
import Dashboard from "../Dashboard/";
import { bindActionCreators } from "redux";
import { setUser, getUserRoles, getUser } from "../Authentication/redux";
import MyInformations from "../Account/MyInformations";
import MyPassword from "../Account/MyPassword";
import Users from "../Users";
import Parameters from "../Parameters";
import Contacts from "../Contacts";
import ContactTypes from "../ContactsType";
import FaqThematic from "../FaqThematic";
import FaqQuestion from "../FaqQuestion";
import { setParams } from "../../reducers/params";
import Cities from "../City";
import City from "../City/City";
import PageView from "../Cms/Pages/View";
import Pages from "../Cms/Pages/Pages";
import Page from "../Cms/Pages/Page";
import PostView from "../Cms/Posts/View";
import Posts from "../Cms/Posts/Posts";
import Post from "../Cms/Posts/Post";
import Seos from "../Cms/Seo/Seos";
import Menu from "../Cms/Menus/Menus";
import PostTypes from "../Cms/PostType/postTypes";
import Templates from "../Cms/Template";
import Promotions from "../Residences/Promotions";
import Typologies from "../Residences/Typologies";
import LotImport from "../Residences/Imports";
import Poi from "../Poi";
import Services from "../Residences/Services";
import Residences from "../Residences";
import Residence from "../Residences/Residence";
import ShowcaseResidence from "../Residences/ShowcaseResidence/ShowcaseResidence";

import Import from "../Import";
import Schools from "../School";
import School from "../School/School"
import Loading from "../../components/Loading";
import Consent from "../Rgpd/Consent";
import Contact from "../Rgpd/Contact";
import Booking from "../Rgpd/Booking";
import Jobs from "../Rh/Job";
import Types from "../Rh/Type";
import HelloBar from "../Cms/HelloBar";
import Articles from "../Press";

class Layout extends Component {
	state = { loaded: false };

	componentDidMount() {
		this.checkAuthentication();
	}

	checkAuthentication = async () => {
		const { setParams, setUser } = this.props;

		try {
			await setParams();
			const data = await session.isAuthenticated();
			setUser(data);
			this.setState({ loaded: true });
		} catch (error) {
			this.props.history.push("/login");
		}
	};

	render() {
		const { loaded } = this.state;
		const { userRoles } = this.props;

		if (!loaded) return <Loading />;
		return (
			<div className="app">
				<Header />
				<ToastContainer
					position="top-center"
					hideProgressBar
					autoClose={3000}
				/>
				<div className="app-body">
					<Sidebar {...this.props} />

					<main className="main">
						<Breadcrumb />
						<Container fluid>
							<Switch>
								<Route
									path="/dashboard"
									name="Dashboard"
									component={Dashboard}
								/>

								<Route
									path="/account/informations"
									name="Mon compte"
									component={MyInformations}
								/>

								<Route
									path="/account/password"
									name="Mon compte"
									component={MyPassword}
								/>

								<Route
									path="/users"
									name="Gestion des membres"
									component={Users}
								/>

								<Route
									path="/villes/creer"
									name="Creation ville"
									component={City}
								/>
								<Route
									path="/villes/:id"
									name="Edition ville"
									component={City}
								/>

								<Route
									path="/villes"
									name="Gestion des villes"
									component={Cities}
								/>

								<Route
									path="/residences/creer"
									name="Creation Résidence"
									component={Residence}
								/>
								<Route
									path="/residences/vitrine/creer"
									name="Creation Résidence"
									component={ShowcaseResidence}
								/>
								<Route
									path="/residences/vitrine/:id"
									name="Creation Résidence"
									component={ShowcaseResidence}
								/>
								<Route
									path="/residences/:id"
									name="Edition résidence"
									component={Residence}
								/>

								<Route
									path="/lots/import"
									name="Log des imports de lots"
									component={LotImport}
								/>
								<Route
									path="/residences"
									name="Gestion des résidences"
									component={Residences}
								/>

								<Route
									path="/parametres"
									name="Gestion des paramètres"
									component={Parameters}
								/>

								<Route
									path="/contacts"
									name="Gestion des contacts"
									component={Contacts}
								/>
								<Route
									path="/contact-types"
									name="Gestion des types contacts"
									component={ContactTypes}
								/>

								<Route
									path="/faq_thematiques"
									name="Gestion des Thématiques de FAQ"
									component={FaqThematic}
								/>

								<Route
									path="/faq_questions"
									name="FAQ"
									component={FaqQuestion}
								/>

								<Route
									path="/pages/edit/:id"
									name="Gestion des pages"
									component={Page}
								/>
								<Route
									path="/pages/edit"
									name="Gestion des pages"
									component={Page}
								/>

								<Route
									exact
									path="/pages/:id"
									name="Gestion des pages"
									component={PageView}
								/>

								<Route
									path="/pages"
									name="Gestion des Articles"
									component={Pages}
								/>

								<Route
									path="/templates"
									name="Gestion des templates"
									component={Templates}
								/>

								<Route
									exact
									path="/post/:id"
									name="Gestion des posts"
									component={PostView}
								/>

								<Route
									path="/posts/edit/:id"
									name="Gestion des posts"
									component={Post}
								/>

								<Route
									path="/posts/edit"
									name="Gestion des posts"
									component={Post}
								/>

								<Route
									path="/posts"
									name="Gestion des Articles"
									component={Posts}
								/>
								<Route
									exact
									path="/seos"
									name="Gestion du seo"
									component={Seos}
								/>

								<Route
									path="/post_types"
									name="post_types"
									component={PostTypes}
								/>

								<Route
									path="/menus"
									name="Gestion des Menus"
									component={Menu}
								/>

								<Route
									path="/promotions"
									name="Gestion des Flap promotionnel"
									component={Promotions}
								/>

								<Route
									path="/typologies"
									name="Gestion des typologies"
									component={Typologies}
								/>

								<Route
									path="/poi"
									name="Gestion des Poi"
									component={Poi}
								/>

								<Route
									path="/services"
									name="Gestion des services"
									component={Services}
								/>

								<Route
									path="/imports"
									name="Liste des imports"
									component={Import}
								/>

								<Route
									path="/ecoles"
									exact
									name="Liste des ecoles"
									component={Schools}
								/>

								<Route
									path="/ecoles/creer"
									exact
									name="création d'une ecoles"
									component={School}
								/>

								<Route
									path="/ecoles/:id"
									name="Edition ecole"
									component={School}
								/>

								{['ROLE_RGPD'].some(r => userRoles.indexOf(r) >= 0) && (
									<Route
										path="/consentements"
										name="Liste des consentements"
										component={Consent}
									/>
								)}

								{['ROLE_RGPD'].some(r => userRoles.indexOf(r) >= 0) && (
									<Route
										path="/rgpd_contacts"
										name="Demandes de contacts"
										component={Contact}
									/>
								)}

								{['ROLE_RGPD'].some(r => userRoles.indexOf(r) >= 0) && (
									<Route
										path="/reservations"
										name="Reservation"
										component={Booking}
									/>
								)}

								<Route
									path="/jobs"
									exact
									name="Liste des jobs"
									component={Jobs}
								/>

								<Route
									path="/jobTypes"
									exact
									name="Liste des types d'emplois"
									component={Types}
								/>

								<Route
									path="/hellobar"
									exact
									name="Hellobar"
									component={HelloBar}
								/>

								<Route
									path="/presse"
									exact
									name="Presse"
									component={Articles}
								/>

								<Redirect from="/" to="/dashboard" />
							</Switch>
						</Container>
					</main>
				</div>
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: getUser(state),
	userRoles: getUserRoles(state),
});

export const mapDispatchToProps = dispatch =>
	bindActionCreators({ setUser, setParams }, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Layout);
