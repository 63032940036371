const API_BASE = "https://www.staging.uxco.ci.whatson.io";
const MEDIA_BASE = "https://medias.uxco.com";
const APICONFIG = {
	title: "Suite étude ::: admin",
	url: API_BASE,
	assetUrl: `${MEDIA_BASE}/img`,
	icons: `${API_BASE}/icomoon/demo.html`,
	ckeditorInstance: `/ckeditor/ckeditor.js`,
	finderParams: {
		url: `${API_BASE}/api`,
		endPointMedia: "/media",
		assetUrl: `${MEDIA_BASE}/img`,
	},
};

module.exports = APICONFIG;
