import React, { Component } from "react";
import PropTypes from "prop-types";
import { SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loading from "../../components/Loading";
import * as schoolService from "../../services/school";
import SchoolForm from "./SchoolForm";
import { toast } from "react-toastify";
import Locals from "../../components/Locals";
import {
	getLocal,
	getLocals,
	setLocal,
	getDefaultLocal,
} from "../../reducers/params";
import * as residenceService from "../../services/residence";

class School extends Component {
	state = {
		loaded: false,
	};

	componentDidMount() {
		this.load();
	}

	load = async () => {
		this.setState({ loaded: false });
		const { defaultLocal, locals } = this.props;

		let data;

		if (this.props.match.params.id) {
			data = await schoolService.view(this.props.match.params.id);

			this.setState({
				data,
				loaded: true,
			});
		} else {
			this.setState({
				loaded: true,
			});
		}
	};

	onSubmit = data => {
		const service = !data.id ? schoolService.create : schoolService.update;
		const caseForm = !data.id ? "Création réussie" : "Modification réussie";

		let associatedCitiesFormated = data.associatedCities.map(e => {
			return {
				id: e.id ? e.id : e["@id"],
			}
		})
		const newData = { ...data,  associatedCities: associatedCitiesFormated };
		return service(newData)
			.then(() => {
				this.props.history.push("/ecoles");
				toast.success(caseForm);
			})
			.catch(error => {
				throw new SubmissionError(error);
			});
	};

	copy = async (locale) =>
	{
		var localeWord = locale === 'en' ? 'l\'Anglais' : 'le Chinois';

		if (
			window.confirm("Voulez vous vraiment copier l école de la résidence vers " + localeWord  + "? Si des traductions sur cette page existe elles seront supprimées")
		) {

			return residenceService.copy(this.state.data.id, locale)
				.then(() => {
					window.location.reload();
				})
				.catch(error => {
					throw new SubmissionError(error);
				});
		}
	}

	render() {
		const { loaded } = this.state;
		const { local, locals, defaultLocal } = this.props;

		if (!loaded) return <Loading />;

		return (
			<div className="animated fadeIn">
				<h1>Ecole</h1>

				<Locals />
				<SchoolForm
					onSubmit={this.onSubmit}
					local={local}
					locals={locals}
					defaultLocal={defaultLocal}
					initialValues={this.state.data}
					copy={this.copy}
				/>
			</div>
		);
	}
}

School.defaultProps = {
	local: null,
	locals: [],
	defaultLocal: null,
};

School.propTypes = {
	local: PropTypes.string,
	locals: PropTypes.array,
	defaultLocal: PropTypes.string,
};

const mapStateToProps = state => ({
	local: getLocal(state),
	locals: getLocals(state),
	defaultLocal: getDefaultLocal(state),
});

export const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			setLocal,
		},
		dispatch,
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(School);
