import { picture } from "../block";

export const avantages = {
	admin: {
		editable: true,
		add: false,
		template: "BlocContainer",
		title: "Item",
		fields: {
			content: {
				admin: {
					template: "BlocText1",
					content:
						'<h5 class="card-title">Card title</h5><p class="card-text">This is a longer card with supportin.</p>',
				},
			},
		},
	},
};

export const presentationContent = {
	tplt: "/residence/residence/01-presentation.html.twig",
	admin: {
		template: "BlocContainer",
		title: "Présentation",
		fields: {
			title: {
				admin: {
					template: "BlocText1",
					content:
						"<h2>En savoir + ...</h2><p>Equitis Romani autem esse ...</p>",
				},
			},
			picture: {
				admin: {
					template: "BlocMedia",
					type: "tplt-picture",
				},
			},
			student: {
				admin: {
					editable: true,
					add: true,
					template: "BlocCollection",
					type: "tplt-slider-horizontal",
					title: "Avantages etudiant",
					defaultTemplates: [avantages],
				},
			},
			parents: {
				admin: {
					editable: true,
					add: true,
					template: "BlocCollection",
					type: "tplt-slider-horizontal",
					title: "Avantages parent",
					defaultTemplates: [avantages],
				},
			},
		},
	},
};

export const serviceContent = {
	tplt: "/residence/residence/02-services.html.twig",
	admin: {
		template: "BlocContainer",
		title: "Vos services",
		fields: {
			lots: {
				admin: {
					template: "BlocModule",
					title: "Liste des services séléctionnées",
				},
			},
		},
	},
};


const temoingnageContentItem = {
	admin: {
		editable: true,
		add: false,
		template: "BlocContainer",
		title: "Item",
		fields: {
			photo: {
				admin: {
					template: "BlocMedia",
				},
			},
			content: {
				admin: {
					template: "BlocText1",
					content:
						'<div class="content"><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta, a odit? Nostrum voluptates nemo dolores ipsum repellendus, laboriosam aut pariatur.</p><h4>Nom de l’étudiant</h4><h5>formation suivie</h5></div>',
				},
			},
		},
	},
};

export const temoingnageContent = {
	tplt: "/residence/residence/04-testimonies.html.twig",
	admin: {
		template: "BlocContainer",
		title: "Témoignages",
		fields: {
			list: {
				admin: {
					editable: true,
					add: true,
					template: "BlocCollection",
					title: "Liste des témoignages",

					defaultTemplates: [temoingnageContentItem],
				},
			},
		},
	},
};

const googIdeasnItem = {
	admin: {
		editable: true,
		add: false,
		template: "BlocContainer",
		title: "Item",
		fields: {
			photo: {
				admin: {
					template: "BlocMedia",
				},
			},
			content: {
				admin: {
					template: "BlocText1",
					content: "<h5>Title Lorem ipsum dolor.</h5>",
				},
			},
		},
	},
};


/**
 * BLOCS MEDIAS
 */
export const videoContent = {
	tplt: "/residence/residence/medias/video.html.twig",
	admin: {
		template: "BlocContainer",
		title: "Video",
		fields: {
			video: {
				admin: {
					template: "BlocMedia",
					type: "tplt-video",
				},
			},
			image: {
				admin: {
					template: "BlocMedia",
				},
			},
		},
	},
};

export const carrouselContent = {
	tplt: "/residence/residence/medias/carrousel.html.twig",
	admin: {
		template: "BlocCollection",
		type: "tplt-slider-txt",
		title: "Carrousel de photos",
		add: true,
		editable: true,
		defaultTemplates: [picture],
	},
};

export const mapContent = {
	tplt: "/residence/residence/medias/map.html.twig",
	admin: {
		template: "BlocContainer",
		title: "Poi’s",
		fields: {
			lots: {
				admin: {
					template: "BlocModule",
					title: "Liste poi’s",
				},
			},
		},
	},
};

export default {
	template: "residence",
	name: "Résidence",
	poiActivated: false,
	statusRelease: 1,
	seo: { sitemap: true },
	services: [],
	add: false,
	editable: true,
	builder: [
		presentationContent,
		serviceContent,
		temoingnageContent,
	],
	medias: [videoContent, carrouselContent, mapContent],
};
