import React, { Component } from "react";
import { Form } from "reactstrap";
import {
	change,
	Field,
	getFormValues,
	reduxForm,
} from "redux-form";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
	WhFieldSelect,
	WhValidator,
} from "../../components/Form/whFields";

class ResidenceTypeForm extends Component {
	state = {
		file: null,
		activeTab: "contents",
		errorsImport: [],
	};

	render() {
		const { handleSubmit, submitting } = this.props;

		const { errorsImport } = this.state;

		return (
			<Form onSubmit={handleSubmit} className="form-horizontal">
				<Field
					label="Type de  résidence : "
					name="type"
					validate={[WhValidator.required]}
					component={WhFieldSelect}
					type="text"
					options={[
						{
							value: 1,
							name: "Résidence classique",
						},
						{
							value: 2,
							name: "Résidence vitrine",
						},
					]}
					required={true}
					multiple={false}
					parse={(value) => parseInt(value)}
				/>

				<LaddaButton
					className="btn btn-success btn-ladda"
					loading={submitting}
					data-style={EXPAND_LEFT}
					type="submit"
				>
					<i className="fa fa-save" />
					&nbsp;Enregistrer
				</LaddaButton>

			</Form>
		);
	}
}

const formName = "ResidenceTypeForm";
ResidenceTypeForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
};

ResidenceTypeForm.defaultProps = {
	error: "",
};

const mapStateToProps = (state) => ({
	currentValues: getFormValues(formName)(state),
});

const mapDispatchToProps = (dispatch) => ({
	changeFieldValue: (field, value) =>
		dispatch(change(formName, field, value)),
});

ResidenceTypeForm = connect(mapStateToProps, mapDispatchToProps)(ResidenceTypeForm);

ResidenceTypeForm = reduxForm({
	form: formName,
})(ResidenceTypeForm);

export default ResidenceTypeForm;
